<template>
  <div class="bg-grey-50">
    <div class="relative flex items-center justify-center min-h-screen">
      <v-card
        class="pb-8 pt-4 px-4 shadow-2xl min-w-352/16 lg:min-w-416/16 rounded-lg overflow-y-scroll"
        max-width="1280"
        outlined
        style="height: calc(100vh - 100px)"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center" justify="center">
            <img src="../assets/logo/logo.png" alt="logo" class="w-15 h-12" />
            <p class="brand mt-3">BẢO ĐẠI ĐƯỜNG</p>
          </v-row>
          <v-row class="flex-col m-0 mt-4">
            <v-col>
              <div class="grid grid-cols-120px/1fr gap-x-2">
                <p>Họ Tên:</p>
                <p>{{ name }}</p>
                <p v-if="address != ''">Địa chỉ:</p>
                <p v-if="address != ''">{{ address }}</p>
                <p>Số điện thoại:</p>
                <p>{{ phone }}</p>
                <p>Ngày khám:</p>
                <p>{{ doctorAt }}</p>
                <p v-if="reason">Lý do tới khám:</p>
                <p v-if="reason">{{ reason }}</p>
                <p v-if="reExamination">Ngày tái khám:</p>
                <p v-if="reExamination">{{ reExamination }}</p>
              </div>
              <div class="h-0.5 bg-primary my-4" />
              <v-data-table
                no-data-text="Không có dữ liệu"
                :headers="headers"
                :items="prescription"
                disable-pagination
                hide-default-footer
              />
              <div class="flex justify-end space-x-4 mt-6 pr-4">
                <p>Thành tiền:</p>
                <p>{{ price }}</p>
              </div>
              <div v-if="howToUse" class="flex space-x-4">
                <span><b>Cách dùng:</b></span>
                <p>{{ howToUse }}</p>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import { required } from "~/utils/validation";
import { mapState } from "vuex";
import { EMPTY } from "~/utils/constants";
import { formatVnd } from "~/utils";

export default {
  name: "PrescriptionForPatient",
  metaInfo() {
    return {
      title: "Đăng nhập | Hệ thống quản lý",
    };
  },
  filters: {
    formatVnd,
  },
  data() {
    return {
      isInputPhone: false,
      phoneModel: null,
      valid: true,
      rules: {
        required,
      },
      headers: [
        { text: "Tên thuốc", value: "name", sortable: false },
        { text: "Số lượng", value: "quantity", sortable: false, align: "center" },
        { text: "Đơn giá", value: "pricePerUnit", sortable: false, align: "end" },
      ],
    };
  },
  computed: {
    ...mapState({
      name: (state) => state.prescriptionForPatient.name || EMPTY,
      address: (state) => state.prescriptionForPatient.address || EMPTY,
      phone: (state) => state.prescriptionForPatient.phone || EMPTY,
      doctorAt: (state) => state.prescriptionForPatient.doctorAt || EMPTY,
      reExamination: (state) => state.prescriptionForPatient.reExamination || EMPTY,
      prescription: (state) =>
        state.prescriptionForPatient.prescription.map((item) => ({
          ...item,
          pricePerPiece: "1.000 vnđ",
        })) || [],
      price: (state) => state.prescriptionForPatient.price || EMPTY,
      howToUse: (state) => state.prescriptionForPatient.howToUse || EMPTY,
      reason: (state) => state.prescriptionForPatient.reason || EMPTY,
    }),
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  opacity: 0.8;
  border: 2px solid transparent;
  border-radius: 5px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.brand {
  color: #fbb040 !important;
  font-family: "Mabella" !important;
  font-size: 30px !important;
}
</style>
